import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Börja måndagen med ett par härliga free spins. Hoppas att lyckan är med dig.`}</p>
    <p>{`##Casumo
Hos Casumo så får idag alla spelare en överraskningsbonus, för att veta vilken bonus du får så behöver du logga in på ditt konto och sedan klicka på mitt konto. Bonusen som man tar emot kan vara allt från free spins, bonuspengar och insättnings bonus.`}</p>
    <p>{`Klicka här för att hämta din överraskning.`}</p>
    <p>{`##Unibet
Hos Unibet så får idag alla spelare 10 free spins på Gonzo Quest då Sverige tog hem en guld medalj i OS igår och Unibet ger därför alla spelare 10 free spins.`}</p>
    <p>{`Hämta 10 free spins – Spela nu!`}</p>
    <p>{`##Betsson
Gör man en insättning på minst 200 kronor idag så får man 20 free spins på Big Bang imorgon efter klockan 18.00.`}</p>
    <p>{`Få 20 free spins hos Betsson – Spela nu!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      